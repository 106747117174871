import { StatusBar } from 'expo-status-bar';
import { TouchableOpacity } from 'react-native';
import { StyleSheet, Text, View, Image, Linking } from 'react-native';
import Contacts from 'react-native-contacts';

export default function App() {

  const downloadVCF =  () => {
    
      // Change the path to the actual location of your VCF file
      const vcfFilePath = require('./assets/BryRodMo.vcf');

      Linking.openURL(vcfFilePath);
    
  };

  return (
    <View style={styles.container}>
      <Image
    source={require('./assets/profilepic.jpg')}
            style={styles.profile_pic}
            resizeMode="contain"
    />
      <Text style={styles.name_text}>Bryan Rodríguez Morales</Text>
      <Text style={styles.title_text}>Creator</Text>
      <View style={styles.line}></View>

      <Text style={styles.description_text}>
      Graphic Designer{"\n"}
      Web/App Developer{"\n"}
      Marketer</Text>


      <TouchableOpacity style={styles.addcontact} onPress={downloadVCF}>
    <Text style={styles.button_text}>ADD TO CONTACTS</Text>
    </TouchableOpacity>

    <View style={styles.contact_text}>
    <Text style={styles.contact_text_Icon}>
      <Image
    source={require('./assets/phone_icon.svg')}
            style={styles.icon_cell}
            resizeMode="contain"
    />787-593-5506{"\n"}<Image
    source={require('./assets/email_icon.svg')}
            style={styles.icon}
            resizeMode="contain"
    />bryrodmo@gmail.com
    </Text>
    </View>
    
    
    
    
    <View style={styles.darkgreen}>
    <Text style={styles.quote_text}>"to be wiser than yesterday"</Text>
    </View>
      
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#7BA49A',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    width: 170,
    height: 4,
    marginBottom: 20,
    backgroundColor: 'white', // Change the color as needed
  },
  profile_pic: {
    marginTop: 24,
    marginBottom: 24,
    borderRadius: 60,
    width: 265,
    height: 265,
  },
  name_text: {
    fontSize: 24,
    marginBottom: 22,
    fontWeight: "bold",
  },
  title_text: {
    fontSize: 24,
    marginBottom: 2,
  },
  description_text: {
    fontSize: 24,
    paddingVertical: 7,
    textAlign: "right",
    marginBottom: 36,
  },
  contact_text: {
    fontWeight: "bold",
    fontSize: 24,
    paddingVertical: 5,
    textAlign: "right",
    marginBottom: 36,
  },
  icon: {
    width: 20,
    height: 16,
    paddingRight: 2,
  },
  icon_cell: {
    width: 20,
    height: 20,
    paddingRight: 2,
  },
  contact_text_Icon: {
    fontWeight: "bold",
    flexDirection: "row",
    fontSize: 24,
    textAlign: "right",
    
  },
  button_text:{
    fontWeight: "500",
  },
  addcontact:{
    height: 40,
    width: 190,
    justifyContent:"center",
    alignItems: "center",
    borderRadius: 20,
    marginBottom: 45,
    shadowColor: '#171717',
    shadowOffset: {width: -2, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
    backgroundColor: "#C0C0C0"
  },
  darkgreen:{
    height: 105,
    width: "100%",
    justifyContent:"center",
    alignItems: "center",
    backgroundColor: "#63877F"
  },
  quote_text:{
    fontStyle: "italic",
    fontWeight: "200",
    fontSize: 24,
    color: 'white',
  },
});
